@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

:root {
  --bg_theme: #ffffff;
  --bg_theme2: #f1f4f9;
  --bg_primary: linear-gradient(180deg, #9945ff 0%, #8531eb 100%);
  --bg_secondary: linear-gradient(
      180deg,
      rgba(153, 69, 255, 0.35) 0%,
      rgba(32, 225, 159, 0.35) 100%
    ),
    linear-gradient(0deg, #01060f, #01060f);
  --bg_grad: linear-gradient(90.69deg, #9945ff -0.25%, #20e19f 100%);

  --primary: #9945ff;
  --main: #111111;
  --main_r: #ffffff;
  --family-jakarta: "Plus Jakarta Sans", sans-serif;
  --bg_progress: rgba(153, 69, 255, 0.7);
}

[data-theme="dark"] {
  --bg_theme: #2f2f30;
  --bg_theme2: #424344;
  --main: #ffffff;
  --main_r: #111111;
  --bg_progress: #ffffff;
}

.TransactionDetails,
.TransactionWindow__WithCurtain {
  z-index: 999 !important;
}

.TransactionWindow__WithCurtain > * {
  color: #666666 !important;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: var(--family-jakarta);
  background: var(--bg_theme2);
  overflow-x: hidden;
}

body > * {
  font-family: var(--family-jakarta);
  color: var(--main);
}

.MuiSlider-root,
.MuiSlider-root > * {
  color: var(--main) !important;
}

#root {
  position: relative;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: initial;
}

.list-disc {
  margin: 0 10px;
}

textarea,
button,
input {
  outline: none;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  display: none;
}

.bg_gradient_text {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: var(--bg_grad);
  color: #0000;
}

.particle-pwe-btn {
  display: none;
}

.sidebar {
  background: #00000066;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}

.team-swiper .swiper-slide {
  width: 270px;
  height: 375px;
}

.nft-swiper .swiper-slide {
  width: 270px;
  height: auto !important;
}

.team-swiper .swiper-button-next,
.team-swiper .swiper-button-prev {
  border-color: #ffffff;
  background:
    (0deg, rgba(255, 255, 255, 0.17), rgba(255, 255, 255, 0.17)),
    -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0)));
  background:
    (0deg, rgba(255, 255, 255, 0.17), rgba(255, 255, 255, 0.17)),
    -o-linear-gradient(right, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  background: (0deg, rgba(255, 255, 255, 0.17), rgba(255, 255, 255, 0.17)),
    linear-gradient(270deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  color: #ffffff;
}

.team-swiper .swiper-button-next.swiper-button-disabled,
.team-swiper .swiper-button-prev.swiper-button-disabled {
  color: #c0c0c0;
  border-color: #c0c0c0;
  background: transparent;
  opacity: 1;
}

.team-swiper .swiper-button-next,
.team-swiper .swiper-button-prev {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  bottom: 10px !important;
  border: 2px solid;
}

.nft-swiper .swiper-button-disabled,
.nft-swiper .swiper-button-disabled svg {
  fill: #747474;
  color: #747474;
  border-color: #747474;
}

[data-theme="dark"] .nft-swiper .swiper-button-disabled,
[data-theme="dark"] .nft-swiper .swiper-button-disabled svg {
  color: #c0c0c0;
  fill: #c0c0c0;
  border-color: #c0c0c0;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 16px;
  font-weight: 700;
}

.team-swiper .swiper-pagination {
  -webkit-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  transform: translate(0, 10px);
}

.team-swiper .swiper-pagination-bullet {
  border: 0;
  background-color: #20e19d8c;
  width: 30px;
  height: 4px;
  border-radius: 3px;
}

.team-swiper .swiper-pagination-bullet-active {
  background: #20e19f !important;
  opacity: 1 !important;
}

.react-select-modal__option,
.react-select__option {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px !important;
  cursor: pointer !important;
}

.swap-icon {
  width: 20px;
  min-width: 20px;
  /* height: 20px; */
  margin-right: 8px;
  border-radius: 100px;
}

.swap-selected-icon {
  border-radius: 100px;
  min-width: 20px;
  width: 20px;
  /* height: 20px; */
  margin-right: 8px;
}

.react-select__control,
.react-select-modal__control {
  border-radius: 12px !important;
  outline: none !important;
  border: 0 !important;
}

.react-select-modal__control {
  min-width: 140px !important;
  min-height: 100% !important;
  border: 1px solid var(--main) !important;
  background: transparent !important;
  color: var(--main) !important;
}

.select-primary .react-select__control {
  background: rgba(31, 224, 157, 0.2) !important;
}

.select-secondary .react-select__control {
  background: rgba(154, 71, 255, 0.2) !important;
}

.isWhite-select .react-select__control {
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid #ffffff14 !important;
}

.bg-\[\#ffffff2e\] {
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.react-select__control:hover {
  border: 0 !important;
}

.react-select-modal__single-value,
.react-select__single-value {
  color: var(--main) !important;
  font-weight: 700 !important;
}

.isWhite-select .react-select__single-value {
  color: white !important;
}

.react-select-modal__indicator-separator,
.react-select-modal__indicator.react-select-modal__dropdown-indicator,
.react-select__indicator-separator,
.react-select__indicator.react-select__dropdown-indicator {
  display: none !important;
}

.table-scroll::-webkit-scrollbar {
  display: none;
}

.meta-loader {
  border: 8px solid transparent;
  border-radius: 50%;
  border-top: 8px solid var(--primary);
  width: 185px;
  height: 185px;
  -webkit-animation: rotate 1.5s linear infinite;
  animation: rotate 1.5s linear infinite;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.MuiSlider-markLabel {
  -webkit-transform: translateX(-85%) !important;
  -ms-transform: translateX(-85%) !important;
  transform: translateX(-85%) !important;
}

.white-pagination .MuiPaginationItem-root.MuiPaginationItem-previousNext svg {
  fill: white !important;
}

.white-pagination .MuiPaginationItem-page,
.white-pagination .MuiPaginationItem-root.MuiPaginationItem-previousNext,
[data-theme="dark"] .MuiPaginationItem-page,
[data-theme="dark"] .MuiPaginationItem-root.MuiPaginationItem-previousNext {
  color: rgb(255 255 255 / 87%) !important;
  border: 1px solid rgb(255 255 255 / 23%) !important;
}

[data-theme="dark"] .MuiSlider-markLabel {
  color: rgb(255 255 255 / 87%) !important;
}

[data-theme="dark"] .react-select-modal__menu,
[data-theme="dark"] .react-select-modal__option,
[data-theme="dark"] .react-select__menu,
[data-theme="dark"] .react-select__option {
  background: var(--bg_theme2) !important;
}

[data-theme="dark"] .react-select-modal__option:hover,
[data-theme="dark"] .react-select__option:hover {
  background: var(--bg_theme) !important;
}

.spinner {
  width: 100%;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
/* 
.modal {
  display: block;
  position: fixed;
  z-index: 9999 !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-open {
  height: 100vh !important;
  overflow: hidden;
}

.modal-content {
  background-color: #fefefe;
  margin: 2% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 430px;
  overflow: hidden;
}

.modal-content > iframe {
  height: 80vh;
} */

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@-webkit-keyframes spin {
  0% {
    opacity: 0.1;
  }

  20% {
    opacity: 0.3;
  }

  40% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    opacity: 0.1;
  }

  20% {
    opacity: 0.3;
  }

  40% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

.glow-btn {
  -webkit-animation: glow 1.5s linear infinite;
  animation: glow 1.5s linear infinite;
}

@keyframes glow {
  0% {
    opacity: 1;
    box-shadow: 1px 1px 20px 7px #9945ff6e;
  }

  25% {
    opacity: 0.8;
    box-shadow: 1px 1px 10px 4px #9945ff6e;
  }

  50% {
    opacity: 0.65;
    boxshadow: 1px 1px 2px 1px #9945ff6e;
  }

  75% {
    opacity: 0.8;
    box-shadow: 1px 1px 10px 4px #9945ff6e;
  }

  100% {
    opacity: 1;
    box-shadow: 1px 1px 15px 7px #9945ff6e;
  }
}

@-webkit-keyframes glow {
  0% {
    opacity: 1;
    box-shadow: 1px 1px 20px 7px #9945ff6e;
  }

  25% {
    opacity: 0.8;
    box-shadow: 1px 1px 10px 4px #9945ff6e;
  }

  50% {
    opacity: 0.65;
    boxshadow: 1px 1px 2px 1px #9945ff6e;
  }

  75% {
    opacity: 0.8;
    box-shadow: 1px 1px 10px 4px #9945ff6e;
  }

  100% {
    opacity: 1;
    box-shadow: 1px 1px 15px 7px #9945ff6e;
  }
}

@media screen and (min-width: 768px) {

  .particle-pwe-iframe-content {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
} 
}

@media screen and (max-width: 640px) {
  .react-select-modal,
  .react-select-modal__control {
    width: 100% !important;
  }
}

.MuiPaginationItem-page.Mui-selected {
  background-color: rgba(153, 69, 255, 0.3) !important;
}

.nft.btn-pagination1,
.nft.btn-pagination2,
.nft.btn-pagination3 {
  width: auto !important;
}

.nft .swiper-pagination-bullet-active {
  background: var(--main) !important;
}

.nft .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
}

.MuiPagination-ul {
  gap: 8px 0 !important;
  justify-content: center !important;
}

.white-pagination .MuiPaginationItem-rounded.MuiPaginationItem-ellipsis,
[data-theme="dark"] .MuiPaginationItem-rounded.MuiPaginationItem-ellipsis {
  color: white !important;
}

.blurry {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #0000008c;
}


@supports (-webkit-appearance: none) {
  input[type="checkbox"] {
    /* Add specific styles for iOS here */
    height: auto
  }
}
